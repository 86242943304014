import { ColumnTypeIdentifier, TableColumnType } from 'components/editor/grid/reduxStore/table.types';

export const defaultRowMeta = {
  __reorder__: 'Row',
};

export const NUMBER_COLUMNS_ALLOWING_PRICING = 3;

export const defaultColumnMeta: TableColumnType = {
  editable: true,
  headerName: 'Text',
  columnType: ColumnTypeIdentifier.TEXT,
  headerAlign: 'center',
  type: 'string',
  field: 'default',
};

export const initialColumnWidth = 333;
export const initialPricingColumnWidth = 180;

export const cellFocusOutEventKeys = ['Enter', 'Tab'];
