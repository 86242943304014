import { ColumnTypeIdentifier, TableRowType } from 'components/editor/grid/reduxStore/table.types';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { CustomTableCell, CustomTableCellInPreview } from '../components/CustomTableCell';
import { isTaxOrDiscountRow } from 'muiTheme/dataGridUtils';
import { formatPriceWithDollarSign } from '../helper';
interface TableNumericCellProps {
  params: GridCellParams;
  columnType: ColumnTypeIdentifier;
  numberOfDecimalPlaces?: number;
  isDocumentLocked?: boolean;
}

export const PRICING_COLUMNS = [ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];

export const TableNumericCell = ({ params, columnType, numberOfDecimalPlaces, isDocumentLocked }: TableNumericCellProps) => {
  const TableCellComponent = isDocumentLocked ? CustomTableCellInPreview : CustomTableCell;

  if (!params) return <></>;

  if (params.value === undefined || params.value === null) {
    return <TableCellComponent params={params} value="" />;
  }

  let displayedValue = params.value.toString();

  const row = params.row as TableRowType;
  const shouldNotAppendDollarSign = !PRICING_COLUMNS.includes(columnType) || !Number(displayedValue) || isTaxOrDiscountRow(row);

  if (shouldNotAppendDollarSign) {
    return <TableCellComponent params={params} value={displayedValue} />;
  }

  displayedValue = formatPriceWithDollarSign(displayedValue, numberOfDecimalPlaces);

  return <TableCellComponent params={params} value={displayedValue} />;
};

export const getIsCellEditingAllowed = ({ row, colDef }: { row: GridRowModel; colDef: GridColDef }): boolean => {
  if (!row?.cellConfig || !row?.cellConfig[colDef.field]) {
    return false;
  } else {
    return row.cellConfig[colDef.field]?.isEditingActive;
  }
};
