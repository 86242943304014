import { SectionsSignaturesType } from '../../../components/editor/providers/SignaturesProvider';
import { BlocksFromSectionsByDocumentIdApiResponse } from 'services/documents/DocumentRepository';

export type SignatureDimensions = {
  width: number;
  height: number;
};

export type SignaturePosition = {
  x: number;
  y: number;
};

export enum SignatureStatus {
  Signed = 'SIGNED',
  UnSigned = 'UNSIGNED',
}

export enum SignatureType {
  Signature = 'SIGNATURE',
}

export enum AssignedSigneeType {
  User = 'USER',
  Person = 'PERSON',
}

export interface SignSignatureApiPayload {
  document_id: string;
  signature_box_id: string;
  first_name: string;
  last_name: string;
  email: string;
  initials: string;
  updated_tables: BlocksFromSectionsByDocumentIdApiResponse[][] | undefined;
}

export interface SignSignaturePayload {
  documentId: string;
  signatureBoxId: string;
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
  updatedTables: BlocksFromSectionsByDocumentIdApiResponse[][] | undefined;
}

interface BaseSignatureBoxApiResponse {
  signature_box_id: string;
  properties: SignatureBoxProperties;
  type: SignatureType;
}

interface SignatureEventApiResponse {
  id: string;
  email: string;
  last_name: string;
  first_name: string;
  initials: string;
  ip_address: string;
  signed_date: string;
}

interface SignedApiResponse {
  status: SignatureStatus.Signed;
  signature_event: SignatureEventApiResponse;
}

interface UnsignedApiResponse {
  status: SignatureStatus.UnSigned;
  signature_event: null;
}

export interface AssignedSigneeApiResponse {
  first_name: string;
  last_name: string;
  email: string;
  type: AssignedSigneeType;
  id: number;
}

interface AssignedApiResponse {
  assigned_signee: AssignedSigneeApiResponse;
}

interface UnAssignedApiResponse {
  assigned_signee: null;
}

interface UnAssignedSignedSignatureBoxApiResponse extends BaseSignatureBoxApiResponse, UnAssignedApiResponse, SignedApiResponse {}
interface UnAssignedUnSignedSignatureBoxApiResponse extends BaseSignatureBoxApiResponse, UnAssignedApiResponse, UnsignedApiResponse {}

interface AssignedSignedSignatureBoxApiResponse extends BaseSignatureBoxApiResponse, AssignedApiResponse, SignedApiResponse {}
interface AssignedUnSignedSignatureBoxApiResponse extends BaseSignatureBoxApiResponse, AssignedApiResponse, UnsignedApiResponse {}

type UnAssignedSignatureBoxApiResponse = UnAssignedSignedSignatureBoxApiResponse | UnAssignedUnSignedSignatureBoxApiResponse;
type AssignedSignatureBoxApiResponse = AssignedSignedSignatureBoxApiResponse | AssignedUnSignedSignatureBoxApiResponse;

export type SignatureBoxApiResponse = UnAssignedSignatureBoxApiResponse | AssignedSignatureBoxApiResponse;
export type SignedSignatureBoxApiResponse = UnAssignedSignedSignatureBoxApiResponse | AssignedSignedSignatureBoxApiResponse;

export interface GetSignaturesByDocumentIdApiResponse {
  sectionsSignatures: { [sectionId: string]: SignatureBoxApiResponse[] };
}

export interface SignatureBoxProperties {
  dimensions: SignatureDimensions;
  position: SignaturePosition;
}

export interface BaseSignatureBox {
  signatureBoxId: string;
  type: SignatureType;
  properties: SignatureBoxProperties;
}

export interface SignatureEvent {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  initials: string;
  ipAddress: string;
  signedDate: string;
}
export interface Signed {
  status: SignatureStatus.Signed;
  signatureEvent: SignatureEvent;
}

export interface UnSigned {
  status: SignatureStatus.UnSigned;
  signatureEvent: null;
}

interface UnAssigned {
  assignedSignee: AssignedSignee | null;
}

export interface AssignedSignee {
  email: string;
  firstName: string;
  lastName: string;
  type: AssignedSigneeType;
  id: number;
}

export interface Assigned {
  assignedSignee: AssignedSignee;
}

export interface UnAssignedSignedSignatureBox extends BaseSignatureBox, UnAssigned, Signed {}
export interface UnAssignedUnSignedSignatureBox extends BaseSignatureBox, UnAssigned, UnSigned {}

export interface AssignedSignedSignatureBox extends BaseSignatureBox, Assigned, Signed {}
export interface AssignedUnSignedSignatureBox extends BaseSignatureBox, Assigned, UnSigned {}

type UnAssignedSignatureBox = UnAssignedSignedSignatureBox | UnAssignedUnSignedSignatureBox;
type AssignedSignatureBox = AssignedSignedSignatureBox | AssignedUnSignedSignatureBox;
export type UnSignedSignatureBox = UnAssignedUnSignedSignatureBox | AssignedUnSignedSignatureBox;
export type SignedSignatureBox = UnAssignedSignedSignatureBox | AssignedSignedSignatureBox;
export type SignatureBox = UnAssignedSignatureBox | AssignedSignatureBox;

export interface SignatureRepository {
  signSignature: (signature: SignSignaturePayload) => Promise<SignedSignatureBox>;
  getSectionsSignaturesByDocumentId: (documentId: string) => Promise<SectionsSignaturesType>;
}
