import { useParams } from 'react-router-dom';
import { SectionsProvider } from './useSectionsForPreview';
import { PreviewGridPage } from './previewGrid';

export function PreviewGridWrapper() {
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  return (
    <SectionsProvider documentId={documentId}>
      <PreviewGridPage />
    </SectionsProvider>
  );
}
