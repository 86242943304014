import { selectContentTable } from '../../../grid/reduxStore/editorSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../grid/reduxStore/Store';
import { useApplyCustomRulesToColumns } from './useApplyCustomRulesToColumns';

export function useApplyRulesToColumn(sectionId: string, blockId: string) {
  const numberOfDecimalPlaces = useSelector(
    (state: RootState) => selectContentTable(state, sectionId, blockId).metadata.pricingDecimalPlaces
  );

  const { applyCustomRulesToColumns } = useApplyCustomRulesToColumns(sectionId, blockId, numberOfDecimalPlaces);

  return { applyCustomRulesToColumns };
}
