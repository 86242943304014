import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { DocumentSectionsResponse } from 'services/documents/DocumentRepository';
import { useGetPreviewGridByDocId } from './useGetPreviewGridByDocId';

interface SectionsContextProps {
  sections: DocumentSectionsResponse;
  sectionsQueryStatus: string;
  updateSections: (newSections: DocumentSectionsResponse) => void;
}

const SectionsContext = createContext<SectionsContextProps | undefined>(undefined);

export const SectionsProvider: React.FC<{ children: ReactNode; documentId: string }> = ({ children, documentId }) => {
  const { data: sectionData, status: sectionsQueryStatus } = useGetPreviewGridByDocId(documentId);
  const [sections, setSections] = useState<DocumentSectionsResponse>({} as DocumentSectionsResponse);
  useEffect(() => {
    if (sectionData && sectionsQueryStatus === 'success') {
      setSections(sectionData);
    }
  }, [sectionData, sectionsQueryStatus]);

  const updateSections = (newSections: DocumentSectionsResponse) => {
    setSections(newSections);
  };

  return <SectionsContext.Provider value={{ sections, sectionsQueryStatus, updateSections }}>{children}</SectionsContext.Provider>;
};

export const useSectionsForPreview = (): SectionsContextProps => {
  const context = useContext(SectionsContext);
  if (!context) {
    throw new Error('useSections must be used within a SectionsProvider');
  }
  return context;
};
