import { useQuery } from 'react-query';
import { AnalyticsResponse } from 'services/analytics/AnalyticsRepository';
import { ApiAnalyticsRepository } from 'services/analytics/ApiAnalyticsRepository';
import { useInsightsContext } from '../InsightsProvider';

export function useQueryViewedSections() {
  const { documentId } = useInsightsContext();
  const { data, isLoading } = useQuery<AnalyticsResponse>(['getAnalytics', documentId], () =>
    ApiAnalyticsRepository.getAnalyticsByDocumentId(documentId)
  );

  const viewedSections = !data ? [] : data.viewers[0].viewedSections;

  return {
    viewedSections,
    isLoading,
  };
}
