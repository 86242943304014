import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ColumnTypeIdentifier, TableRowType } from '../../../grid/reduxStore/table.types';
import { TableNumericCell } from '../Table/tableUtils/customCellHelpers';
import { Box, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface PreviewEditableCellProps {
  params: GridRenderCellParams;
  columnType: ColumnTypeIdentifier;
  pricingDecimalPlaces?: number;
  updateRows: (updatedRow: TableRowType) => void;
  isDocumentLocked: boolean;
}

const StyledArrowButton = styled(Button)({
  height: '0.4rem',
  minWidth: '0.9rem',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: '#f5f5f5',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const iconStyle = { fontSize: '0.9rem' };

const UpDownArrowIcons = () => {
  return (
    <Box display="flex" flexDirection="column" paddingRight={1}>
      <StyledArrowButton>
        <ArrowDropUpIcon data-testid="arrow-drop-up" style={iconStyle} />
      </StyledArrowButton>
      <StyledArrowButton>
        <ArrowDropDownIcon data-testid="arrow-drop-down" style={iconStyle} />
      </StyledArrowButton>
    </Box>
  );
};

const PreviewEditableCellWrapper = ({
  params,
  columnType,
  pricingDecimalPlaces,
  updateRows,
  isDocumentLocked,
}: PreviewEditableCellProps): JSX.Element => {
  const { row, colDef, value, isEditable } = params;
  const label = { inputProps: { 'aria-label': 'Optional row' } };
  const isCheckboxAvailable = (row?.isOptionalToggleAvailable && colDef['columnType'] === ColumnTypeIdentifier.OPTIONAL) ?? false;

  if (isEditable) {
    return (
      <Box component="span" display="flex" alignItems="center" gap="3px">
        {String(value)} <UpDownArrowIcons />
      </Box>
    );
  }
  if (isCheckboxAvailable) {
    return (
      <Checkbox
        {...label}
        className="py-row-optional"
        data-testid="row-optional-preview-checkbox"
        checked={row?.isRowOptional ?? false}
        onChange={() => updateRows({ ...row, isRowOptional: !row?.isRowOptional })}
      />
    );
  }

  return (
    <TableNumericCell
      isDocumentLocked={isDocumentLocked}
      params={params}
      columnType={columnType}
      numberOfDecimalPlaces={pricingDecimalPlaces}
    />
  );
};

export default PreviewEditableCellWrapper;
