import { useCallback, useMemo, useState } from 'react';
import { gridPixelSize } from '../../../shared/gridConfig';
import { DataGridPro, GridCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { PreviewBlock } from './PreviewBlock';
import {
  ColumnTypeIdentifier,
  TableColumnType,
  TableRowType,
  TableTypeIdentifier,
  TotalRowTypesIdentifier,
} from '../../../grid/reduxStore/table.types';
import { useApplyCustomRulesToColumnsInPreview } from './useApplyCustomRulesToColumnsInPreview';
import { getIsCellEditingAllowed } from '../Table/tableUtils/customCellHelpers';
import { DEFAULT_DECIMAL_PLACES } from '../Table/defaultData';
import { getTextRowsWithCalculatedSubtotals } from '../Table/tableUtils/calculateSubTotalsPerRow';
import PricingTableTotalFooterRow from '../Table/PricingTotalsFooter/PricingTableTotalFooterRow';
import { useTableCellModes } from '../Table/useTableCellModes';
import getTotalFooterRowValue from '../Table/PricingTotalsFooter/getTotalFooterRowValue';
import { FooterWrapper, FORMATTER_KEY } from '../Table/PricingTotalsFooter/PricingTableTotalsFooter';
import { useSectionsForPreview } from 'pages/editor/useSectionsForPreview';
import { BlockContent } from 'components/editor/grid/reduxStore/editorSlice';
import {
  BlocksFromSectionsByDocumentIdApiResponse,
  DocumentSectionsResponse,
  SectionByDocumentIdApiResponse,
} from 'services/documents/DocumentRepository';

interface PreviewTableBlockProps {
  block: BlockContent;
  isDocumentLocked: boolean;
  section: SectionByDocumentIdApiResponse;
}

function updateSectionWithBlockUpdate(
  section: SectionByDocumentIdApiResponse,
  blockId: string,
  updatedRows: TableRowType[],
  updateSections: (newSections: DocumentSectionsResponse) => void,
  sections: DocumentSectionsResponse,
  sectionId: string
) {
  const updatedSection: SectionByDocumentIdApiResponse = {
    ...section,
    blocks: section.blocks.map((existingBlock) => {
      let block: BlocksFromSectionsByDocumentIdApiResponse;
      if (existingBlock.gridId === blockId) {
        block = {
          ...existingBlock,
          content: {
            ...existingBlock.content,
            rows: updatedRows,
            columns: existingBlock.content?.columns || [],
            metadata: existingBlock.content?.metadata || {
              tableType: TableTypeIdentifier.TEXT_TABLE,
              pricingDecimalPlaces: DEFAULT_DECIMAL_PLACES,
            },
          },
        };
      } else {
        block = existingBlock;
      }

      return block;
    }),
  };
  updateSections({ sections: sections.sections.map((sec) => (sec.id === sectionId ? updatedSection : sec)) });
}

export function PreviewTableBlock({ block, isDocumentLocked, section }: PreviewTableBlockProps) {
  const apiRef = useGridApiRef();
  const { sections, updateSections } = useSectionsForPreview();
  const blockId = block.blockConfig.id;
  const sectionId = section.id;
  const columnsData = block?.contentTable?.columns;

  const tableColumns = useMemo(
    () =>
      (isDocumentLocked
        ? columnsData?.filter((column: TableColumnType) => column.columnType !== ColumnTypeIdentifier.OPTIONAL)
        : columnsData || []) as TableColumnType[],
    [block?.contentTable?.columns]
  );
  const contentTableRows = block?.contentTable?.rows || [];
  const filteredRows = useMemo(() => contentTableRows.filter((row) => !row.isRowHidden), [contentTableRows]);
  const [allTableRows, setAllTableRows] = useState<TableRowType[]>(filteredRows);
  const { handleCellClick, handleCellModesModelChange, cellModesModel } = useTableCellModes();

  const { applyCustomRulesToColumnsInPreview } = useApplyCustomRulesToColumnsInPreview(
    block.blockConfig.id,
    block.contentTable?.metadata.pricingDecimalPlaces || DEFAULT_DECIMAL_PLACES
  );

  const handleIsCellEditable = (params: GridCellParams): boolean => {
    const { row, colDef } = params;
    if (isDocumentLocked) {
      return false;
    }

    const isCellEditingAllowed = getIsCellEditingAllowed({ row, colDef });

    return isCellEditingAllowed;
  };

  const updateRowsOptionalStatus = useCallback(
    (updatedRow: TableRowType) => {
      const { rows } = apiRef.current.store.value;
      const { dataRowIdToModelLookup, dataRowIds } = rows;
      const updatedRows = dataRowIds.map((rowId: string) =>
        dataRowIdToModelLookup[rowId].id === updatedRow.id
          ? { ...dataRowIdToModelLookup[rowId], isRowOptional: updatedRow.isRowOptional, ...updatedRow }
          : dataRowIdToModelLookup[rowId]
      );
      setAllTableRows(updatedRows);

      if (section) {
        updateSectionWithBlockUpdate(section, blockId, updatedRows, updateSections, sections, sectionId);
      }
    },
    [apiRef, section, block, blockId, sections, sectionId, updateSections]
  );

  const gridColumns = useMemo(
    () => applyCustomRulesToColumnsInPreview(tableColumns, updateRowsOptionalStatus, isDocumentLocked),
    [tableColumns, applyCustomRulesToColumnsInPreview, updateRowsOptionalStatus]
  );
  const { textRowsWithCalculatedSubtotalsColumn, sumOfSubtotalColumn } = getTextRowsWithCalculatedSubtotals(allTableRows, tableColumns);
  const { totalRows = null, tableType = null } = block?.contentTable?.metadata ?? {};
  const isPricingTable = tableType === TableTypeIdentifier.PRICING_TABLE;

  return (
    <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
      <DataGridPro
        apiRef={apiRef}
        rows={textRowsWithCalculatedSubtotalsColumn}
        columns={gridColumns}
        className={'py-selected-state'}
        isCellEditable={(params) => handleIsCellEditable(params)}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        sx={{
          width: block.blockConfig.width,
          ...(isPricingTable && {
            borderRadius: 'var(--unstable_DataGrid-radius) var(--unstable_DataGrid-radius) 0 0',
          }),
        }}
      />
      {isPricingTable && (
        <FooterWrapper>
          {totalRows?.map((row) => {
            const { isHidden, displayedValue } = getTotalFooterRowValue(row);
            const rowValue = row.rowType === TotalRowTypesIdentifier.SUBTOTAL ? sumOfSubtotalColumn : row.subtotal;
            const value = row[FORMATTER_KEY];
            return (
              <PricingTableTotalFooterRow
                key={row.id}
                title={row.title}
                rowType={row.rowType as TotalRowTypesIdentifier}
                subtotalValue={rowValue}
                priceValue={value}
                isRowSelected={false}
                pricingDecimalPlaces={block?.contentTable?.metadata?.pricingDecimalPlaces || DEFAULT_DECIMAL_PLACES}
              >
                {!isHidden && <span> {displayedValue}</span>}
              </PricingTableTotalFooterRow>
            );
          })}
        </FooterWrapper>
      )}
    </PreviewBlock>
  );
}
