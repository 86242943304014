import { ColumnTypeIdentifier, RowTypesIdentifier, TableColumnType, TableRowType } from '../../../../grid/reduxStore/table.types';

const DEFAULT_PRICING_TABLE_TOTAL_PRECISION = 5;
function groupColumnsByType(columns: TableColumnType[]) {
  return columns.reduce((acc, column) => {
    const { columnType } = column;

    if (!acc[columnType]) {
      acc[columnType] = [];
    }

    acc[columnType].push(column);
    return acc;
  }, {} as Record<ColumnTypeIdentifier, TableColumnType[]>);
}

function isRowCalculationIncluded(row: TableRowType): boolean {
  return (
    (row.rowType === RowTypesIdentifier.BODY && !row?.isOptionalToggleAvailable) || (row?.isOptionalToggleAvailable && row?.isRowOptional)
  );
}

export function getTextRowsWithCalculatedSubtotals(
  textRows: TableRowType[],
  allColumns: TableColumnType[],
  precision = DEFAULT_PRICING_TABLE_TOTAL_PRECISION
): { textRowsWithCalculatedSubtotalsColumn: TableRowType[]; sumOfSubtotalColumn: number } {
  const groupedColumnsByType = groupColumnsByType(allColumns);
  const subtotalColumn = groupedColumnsByType[ColumnTypeIdentifier.SUBTOTAL]?.[0];
  const priceColumn = groupedColumnsByType[ColumnTypeIdentifier.PRICE]?.[0];

  if (!priceColumn || !subtotalColumn) {
    return { textRowsWithCalculatedSubtotalsColumn: textRows, sumOfSubtotalColumn: 0 };
  }

  let sumOfSubtotalColumn = 0;
  const multiplierColumns = groupedColumnsByType[ColumnTypeIdentifier.MULTIPLIER] || [];

  const textRowsWithCalculatedSubtotalsColumn = textRows.map((row) => {
    const priceValueStr = row[priceColumn.field];
    const rowTypeIsBody = row.rowType === RowTypesIdentifier.BODY;

    if (!rowTypeIsBody || !priceValueStr) return row;

    let priceValue = parseFloat(priceValueStr);
    if (isNaN(priceValue)) return row;

    multiplierColumns.forEach((multiplierCol) => {
      const multiplierValueStr = row[multiplierCol.field];
      const multiplierValue = parseFloat(multiplierValueStr);

      if (!isNaN(multiplierValue)) {
        priceValue *= multiplierValue;
      }
    });

    const roundedSubtotal = Number(priceValue.toFixed(precision));
    sumOfSubtotalColumn += isRowCalculationIncluded(row) ? roundedSubtotal : 0;

    return {
      ...row,
      [subtotalColumn.field]: roundedSubtotal,
    };
  });

  return { textRowsWithCalculatedSubtotalsColumn, sumOfSubtotalColumn };
}
