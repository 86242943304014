export enum TableColumnAddPositions {
  BEFORE = 0,
  AFTER = +1,
}

export enum TableSettingsTypes {
  TABLE_ROWS = 'TABLE_ROWS',
  TABLE_COLUMNS = 'TABLE_COLUMNS',
  TABLE_DEFAULTS = 'TABLE_DEFAULTS',
  TABLE_FOOTER_ROWS = 'TABLE_FOOTER_ROWS',
}
