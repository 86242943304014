export const ACTIVITY_TYPES = [
  'document-trashed',
  'document-sent',
  'document-won',
  'document-lost',
  'document-created',
  'document-signed',
] as const;

type DocumentTrashedActivity = {
  type: 'document-trashed';
  createdAt: string;
};

type DocumentSentActivity = {
  type: 'document-sent';
  createdAt: string;
};

type DocumentWonActivity = {
  type: 'document-won';
  createdAt: string;
};

type DocumentLostActivity = {
  type: 'document-lost';
  createdAt: string;
};

type DocumentCreatedActivity = {
  type: 'document-created';
  createdAt: string;
  user: {
    name: string;
    id: number;
  };
};

type DocumentSignedActivity = {
  type: 'document-signed';
  createdAt: string;
  signee: {
    name: string;
    initials: string;
    email: string;
  };
};

export type ActivityResponse =
  | DocumentTrashedActivity
  | DocumentSentActivity
  | DocumentWonActivity
  | DocumentLostActivity
  | DocumentCreatedActivity
  | DocumentSignedActivity;

export type ActivitiesResponse = {
  activities: ActivityResponse[];
};

export type ActivityType = (typeof ACTIVITY_TYPES)[number];

export interface ActivitiesRepository {
  getActivities: (documentId?: string) => Promise<ActivitiesResponse>;
}
