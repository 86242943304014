import { TableRowType, TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';

interface CalculationContext {
  sumOfSubtotalColumn: number;
  discountValue: number;
  totalsValue: number;
}

const FORMATTER_KEY = 'price';
const PERCENT_SIGN = '%';

const calculatePercentageValue = (value: number): number => value / 100;

const getRowNumericValue = (
  row: TableRowType
): {
  value: number;
  isPercentage: boolean;
} => {
  const value = Number(row[FORMATTER_KEY]) || 0;
  const valueFormatter = row?.cellConfig?.[FORMATTER_KEY]?.valueFormatter || '';
  const isPercentage = valueFormatter === PERCENT_SIGN;

  return { value, isPercentage };
};

const calculateDiscountTotal = (rows: TableRowType[], sumOfSubtotalColumn: number): number => {
  return rows.reduce((total, row) => {
    if (row.rowType !== TotalRowTypesIdentifier.DISCOUNT) return total;

    const { value, isPercentage } = getRowNumericValue(row);
    return total + (isPercentage ? sumOfSubtotalColumn * calculatePercentageValue(value) : value);
  }, 0);
};

const calculateRowValue = (row: TableRowType, context: CalculationContext): number => {
  const { value, isPercentage } = getRowNumericValue(row);
  const percentageValue = calculatePercentageValue(value);

  switch (row.rowType) {
    case TotalRowTypesIdentifier.SUBTOTAL:
      return context.sumOfSubtotalColumn;

    case TotalRowTypesIdentifier.DISCOUNT: {
      const discountAmount = isPercentage ? context.sumOfSubtotalColumn * percentageValue : value;
      context.totalsValue -= discountAmount;
      return discountAmount;
    }
    case TotalRowTypesIdentifier.TAX: {
      const taxAmount = isPercentage ? (context.sumOfSubtotalColumn - context.discountValue) * percentageValue : value;
      context.totalsValue += taxAmount;
      return taxAmount;
    }

    case TotalRowTypesIdentifier.TOTAL:
      return context.totalsValue;

    default:
      return value;
  }
};

export const updateTotalRowsWithSubtotalCalculation = (rows: TableRowType[], sumOfSubtotalColumn: number): TableRowType[] => {
  const context: CalculationContext = {
    sumOfSubtotalColumn,
    discountValue: calculateDiscountTotal(rows, sumOfSubtotalColumn),
    totalsValue: sumOfSubtotalColumn,
  };

  return rows.map((row) => ({
    ...row,
    subtotal: calculateRowValue(row, context),
  }));
};
