import CellDropDown from './components/CellDropDown';
import { TableRowType } from '../../../grid/reduxStore/table.types';
import { useTableManipulation } from './useTableManipulation';
import { useTableRowManipulation } from '../../../SidePanel/content/TableSettings/RowSettings/useTableRowManipulation';

type TooltipInputProps = {
  blockId: string;
  sectionId: string;
  valueFormatter: string;
  isHidden: boolean;
  cellConfigObject: Record<string, string | number | boolean> | undefined;
  data: TableRowType;
};

const FORMATTER_KEY = 'price';
const HIDE_CELL_OPTION = 'Hide cell';

const CellDropdownWrapper = ({
  blockId,
  sectionId,
  data,
  valueFormatter,
  isHidden,
  cellConfigObject,
}: TooltipInputProps): React.ReactElement => {
  const { addSlotRowField } = useTableManipulation();
  const { updateTableNonFooterRows } = useTableRowManipulation();
  const optionsMenu = data?.optionsMenu;

  const changeCellFormatValue = async (value: string) => {
    try {
      let updatedCellConfigObject: NonNullable<unknown>;
      if (value !== HIDE_CELL_OPTION) {
        updatedCellConfigObject = { ...cellConfigObject, valueFormatter: value };
      } else {
        updatedCellConfigObject = { ...cellConfigObject, hideCell: !isHidden };
      }

      const updatedSlotRows = addSlotRowField({
        blockId,
        sectionId,
        field: FORMATTER_KEY,
        id: data?.id,
        cellConfig: updatedCellConfigObject,
      });

      if (!updatedSlotRows) return;
      await updateTableNonFooterRows(blockId, updatedSlotRows, data);
    } catch (error) {
      throw new Error('Error while updating table data');
    }
  };

  return (
    <>
      <span className="py-value-formatter">{`${valueFormatter}`}</span>
      <CellDropDown
        hideCellValue={HIDE_CELL_OPTION}
        isHidden={isHidden}
        selectedValue={valueFormatter}
        changeCellFormatValue={changeCellFormatValue}
        options={optionsMenu}
      />
    </>
  );
};

export default CellDropdownWrapper;
