import { TableRowCellConfigExtraDataType } from 'components/editor/grid/reduxStore/table.types';

import { TableRowType } from 'components/editor/grid/reduxStore/table.types';
import { cellMenu } from '../defaultData';
import { getCommaSeparatedNumber } from 'components/editor/hooks/useFieldValidator';

type GetTotalFooterRowValueReturnType = {
  isHidden: boolean;
  displayedValue: string;
  cellConfigObject: TableRowCellConfigExtraDataType;
  valueFormatter: string;
  value: string;
};

const getTotalFooterRowValue = (row: TableRowType): GetTotalFooterRowValueReturnType => {
  const CURRENCY_SIGN = cellMenu[0].sign;
  const FORMATTER_KEY = 'price';

  const value = row[FORMATTER_KEY];
  const cellConfigObject = (row?.cellConfig?.[FORMATTER_KEY] || {}) as TableRowCellConfigExtraDataType;
  const currencyOrPercentageSymbol = (cellConfigObject?.valueFormatter || '') as string;
  const isHidden = !!cellConfigObject?.hideCell ?? false;
  const valueFormatter = (cellConfigObject?.valueFormatter || '') as string;
  const formattedValue = getCommaSeparatedNumber(value);
  const displayedValue =
    currencyOrPercentageSymbol === CURRENCY_SIGN
      ? `${currencyOrPercentageSymbol} ${formattedValue}`
      : `${formattedValue} ${currencyOrPercentageSymbol}`;

  return { isHidden, displayedValue, cellConfigObject, value, valueFormatter };
};

export default getTotalFooterRowValue;
