import { AnalyticsRepository, AnalyticsResponse } from './AnalyticsRepository';
import { analyticsClient } from '../api';

const getAnalyticsByDocumentId = async (documentId?: string): Promise<AnalyticsResponse> => {
  if (!documentId) {
    return Promise.resolve({ viewers: [], updatedAt: '' } as AnalyticsResponse);
  }

  return analyticsClient.get<AnalyticsResponse>(`/document/${documentId}`);
};

export const ApiAnalyticsRepository: AnalyticsRepository = {
  getAnalyticsByDocumentId: getAnalyticsByDocumentId,
};
