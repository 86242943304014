import { Avatar } from '@mui/material';
import IcoDocuments from 'components/icon/icons/IcoDocuments';
import IcoLost from 'components/icon/icons/IcoLost';
import IcoSent from 'components/icon/icons/IcoSent';
import IcoSignaturePen from 'components/icon/icons/IcoSignaturePen';
import IcoTrash from 'components/icon/icons/IcoTrash';
import IcoWon from 'components/icon/icons/IcoWon';
import { ActivityType } from 'services/activities/ActivitiesRepository';
import { Activity } from '../activities/types';
import { OverlappableAvatars } from './OverlappableAvatars';

const iconMap: { [key in ActivityType]: JSX.Element | null } = {
  'document-created': <IcoDocuments />,
  'document-trashed': <IcoTrash />,
  'document-won': <IcoWon />,
  'document-lost': <IcoLost />,
  'document-sent': <IcoSent />,
  'document-signed': <IcoSignaturePen />,
};

export function ActivityAvatar({ activity }: { activity: Activity }) {
  if (activity.isViewedByMultipleUsers) {
    return <OverlappableAvatars users={activity.who} />;
  }

  if (iconMap[activity.type]) {
    return <Avatar>{iconMap[activity.type]}</Avatar>;
  }

  return null;
}
