import { activitiesClient } from '../api';
import { ActivitiesRepository, ActivitiesResponse } from './ActivitiesRepository';

const getActivitiesByDocumentId = async (documentId?: string): Promise<ActivitiesResponse> => {
  if (!documentId) {
    return Promise.resolve({ activities: [] } as ActivitiesResponse);
  }
  const response = await activitiesClient.get<ActivitiesResponse>(`/document/${documentId}`);
  return response;
};

export const ApiActivitiesRepository: ActivitiesRepository = {
  getActivities: getActivitiesByDocumentId,
};
